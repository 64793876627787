<!--
   Category 页面的大多数代码都是直接从index页面拷贝的
  不同的是 Category 展示的数据是根据url中的typeid作为查询依据获取的数据
-->
<template>
<div>
  <Header1></Header1>
  <Header></Header>
  <!-- banner图 -->
    <div class="banner" :style="{backgroundImage: 'url(' + coverImgUrl() +')' ,  height: getHeight()}">
      <div class="wrap" :style="{height: getHeight()}">
        <div class="word-wrapper">
          <span class="title">{{type_name}}</span> <br>
          <span class="dec"></span>
        </div>
      </div>
    </div>
  <!-- content -->
  <BlogItem :blogs="blogs"></BlogItem>
  <!-- bottom -->
  <Version></Version>
  <el-backtop class="up"> <i class="el-icon-top"></i></el-backtop>
</div>
</template>


<script>
import Header1 from "../components/Header1.vue"  ;
import Header from "../components/Header.vue"  ;
import Banner from "../components/Banner.vue"  ;
import BlogItem from "../components/BlogItem.vue"  ;
import Version from "../components/Version.vue"  ;
export default {
  name : 'Category' , 
  components:{Header1 , Banner,Header,BlogItem , Version} , 
  data(){
    return{
      blogs:{} , 
      type_name:'' , 
      // currentPage:1 , 
      // total:0 , 
      // pageSize:5 , 
      collorArr:["pink" , "#AFEEEE" , "#abf" , "#9370DB" , "#F4A460" , "#90EE90" , "#6495ED" , "	#A9A9A9" , "#DDA0DD" , "	#F0E68C"] ,
      bgCol:"#abf",
      imgUrls:[]
    }
  } ,
  methods:{
    getHeight(){
      return window.innerHeight + 'px' ; 
    } , 
    // 根据header组件传过来的typename查询blogs
    getBlogsByName(){
      const _this = this ; 
      _this.$axios.get("/getBlogsByTypeName?type_name="+ this.type_name).then(res => {
        this.blogs = res.data.data.data ; 
      }) ; 
    } ,
    // page(currentPage){
    //   const _this = this ; 
    //   _this.$axios.get("/blogs?currentPage=" + currentPage).then(res => {
    //     _this.blogs = res.data.data.records ; 
    //     _this.currentPage = res.data.data.current ; 
    //     _this.total = res.data.data.total ; 
    //     _this.pageSize = res.data.data.size ; 
    //     console.log(_this.blogs);
    //   }) ; 
    // } ,
    coverImgUrl(){
      // let num = Math.floor(Math.random()*10);  
      let num = Math.floor(Math.random()*this.imgUrls.length);  
      return this.imgUrls[num] ; 
    } , 
    // 获取分类的背景图片
    coverImgUrlCat(){
      const _this = this ; 
      _this.$axios.get("/getBgCategory").then(res => {
        // console.log(res) ; 
        this.imgUrls = res.data.data ; 
      }) ; 
    }
  } , 
  created(){
    this.type_name = this.$route.params.typeName ; 
    this.coverImgUrlCat() ; 
    this.getBlogsByName() ; 
    this.coverImgUrl() ;
    // this.page(this.currentPage) ; 
    // 页面加载完毕，展示为顶部
    window.scroll(0,0) ; 
  } ,
  watch:{
    // 监听url变化，获取要查询的typeid
    $route(to,from){
      let url = to.path ; 
      let urls = url.split('/') ; 
      let type_name = urls[urls.length-1]; 
      // 然后重新查询，并重新渲染页面
      // const _this = this ; 
      this.$axios.get("/getBlogsByTypeName?type_name="+ type_name).then(res => {
        this.blogs = res.data.data.data ; 
        this.type_name = res.data.data.type_name ; 
      }) ; 
      location.reload() ; 
    }
  }
}
</script>


<style scoped>
.banner{
  width: 100vw;
  height: 100vh;
  background-image: url("../img/k-on1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* position: absolute;
  left: 0;
  top: 0; */
  overflow: hidden;
  opacity: 1;
}
.banner .wrap{
  width: 100vw;
  height: 100vh;
  /* opacity: 0.5; */
  background-color: rgba( 0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.banner .wrap .word-wrapper{
  position: absolute;
  top: 35%;
  /* padding-top: 280px; */
}
.banner .wrap .word-wrapper .title{
  color: white;
  font-size: 40px;
}
.banner .wrap .word-wrapper .dec{
  color: white;
  font-size: 15px;
}
.up{
  background-color: rgba(255, 255, 255, 0.5);
  
}
</style>